
require('./bootstrap');

window.Vue = require('vue');

//Vue.component('showlowriseprice', require('./components/ShowLowRisePriceComponent.vue').default);

// const app = new Vue({
//     el: '#app',
// });
